<style scoped>
.c-panel {
  margin: 0px;
  padding: 0px 20px;
}
</style>

<template>
  <el-dialog v-if="m" :title="this.id == 0 ? '通道增加' : '通道数据修改'" :visible.sync="isShow" top="6vh" width="650px" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false" custom-class="full-dialog" :show-close="false">
    <div class="vue-box">
      <div class="c-panel">
        <br />
        <el-form size="small" :inline="true" label-width="150px">
          <el-form-item label="通道名称:">
            <el-input v-model="m.channelName" style="width: 350px"></el-input>
          </el-form-item>
          <el-form-item label="通用通道编号:">
            <el-input v-model="m.channelId" style="width: 350px"></el-input>
          </el-form-item>
          <el-form-item label="易支付通道编号:">
            <el-input v-model="m.channelEpayId" style="width: 350px"></el-input>
          </el-form-item>
          <el-form-item label="通道网关:">
            <el-input v-model="m.gateway" style="width: 350px"></el-input>
          </el-form-item>
          <el-form-item label="商户号:">
            <el-input v-model="m.mchId" style="width: 350px"></el-input>
          </el-form-item>
          <el-form-item label="密钥:">
            <el-input v-model="m.mchKey" style="width: 350px"></el-input>
          </el-form-item>
          <el-form-item label="通道编号:">
            <el-input v-model="m.channelNumber" style="width: 350px"></el-input>
          </el-form-item>
          <el-form-item label="支付方法:">
            <el-input v-model="m.method" style="width: 350px"></el-input>
          </el-form-item>
          <el-form-item label="异步通知地址:">
            <el-input v-model="m.notifyUrl" style="width: 350px"></el-input>
          </el-form-item>
          <el-form-item label="异步通知返回内容:">
            <el-input v-model="m.notifyMsg" style="width: 350px"></el-input>
          </el-form-item>
          <el-form-item label="限额:">
            <el-input v-model="m.limits" style="width: 350px"></el-input>
          </el-form-item>
          <el-form-item label="是否启用:">
            <el-select v-model="m.status">
              <el-option label="开启" :value="1"></el-option>
              <el-option label="关闭" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="微信客服:">
            <el-select v-model="m.useKf">
              <el-option label="开启" :value="1"></el-option>
              <el-option label="关闭" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="多账户收款:">
            <el-select v-model="m.pollingStatus">
              <el-option label="开启" :value="1"></el-option>
              <el-option label="关闭" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注:">
            <el-input type="text" v-model="m.remarks" style="width: 350px"></el-input>
            <br />
          </el-form-item>
          <br />
        </el-form>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="(isShow = false), claerData()">取 消</el-button>
      <el-button size="small" type="primary" @click="ok()">操 作</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      id: 0,
      zftlist: [],
      m: {
        channelId: "", // 从菜单配置文件里传递过来的参数
        channelName: "",
        channelNumber: "",
        channelEpayId: "",
        limits: 5000,
        mchId: "",
        mchKey: "",
        notifyUrl: "",
        remarks: "",
        status: 0,
        gateway: "",
        method: "",
        notifyMsg: "",
        useKf: 0,
        pollingStatus: 0
      },
      p: {
        // 查询参数
        page: 1,
        limit: 20,
      },
    };
  },
  methods: {
    // 打开
    open: function (data) {
      this.id = data.id;
      this.isShow = true;
      if (this.id == 0 || data == 0) {
        // 如果是添加
        this.id = 0;
      } else {
        this.m.channelId = data.channelId;
        this.m.channelName = data.channelName;
        this.m.channelNumber = data.channelNumber;
        this.m.limits = data.limits;
        this.m.limits = data.limits;
        this.m.mchId = data.mchId;
        this.m.mchKey = data.mchKey;
        this.m.notifyUrl = data.notifyUrl;
        this.m.remarks = data.remarks;
        this.m.status = data.status;
        this.m.gateway = data.gateway;
        this.m.channelEpayId = data.channelEpayId;
        this.m.method = data.method;
        this.m.notifyMsg = data.notifyMsg;
        this.m.useKf = data.useKf;
        this.m.pollingStatus = data.pollingStatus;
      }
    },
    // 表单验证
    submit_check: function () {
      return "ok";
    },
    // 提交
    ok: function () {
      // 表单验证
      if (this.submit_check() != "ok") {
        return;
      }
      // 开始增加或修改
      let self = this;
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      if (this.id == 0) {
        this.sa.ok("暂不支持添加");
      } else {
        // id != 0 为修改
        this.sa.ajax(
          "/v1/channel/edit/" + this.id,
          this.m,
          function () {
            self.sa.alert(
              "修改成功",
              function () {
                self.$parent.f5(); // 父视图刷新
                self.isShow = false;
                self.claerData();
              },
              defaultCfg
            );
          },
          defaultCfg
        );
      }
    },
    claerData() {
      this.m.channelId = "";
      this.m.channelName = "";
      this.m.channelNumber = "";
      this.m.limits = "";
      this.m.limits = "";
      this.m.mchId = "";
      this.m.mchKey = "";
      this.m.notifyUrl = "";
      this.m.remarks = "";
      this.m.status = "";
      this.m.gateway = "";
      this.m.channelEpayId = "";
      this.m.method = "";
      this.m.notifyMsg = "";

    },
  },
  created: function () {
  },
};
</script>
