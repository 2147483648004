<style scoped>
</style>
<template>
  <div class="vue-box">
    <div class="c-panel">
      <!-- 参数栏 -->
      <div class="c-title">自定义通道列表</div>
      <el-form size="mini">
        <el-form-item>
          <el-button type="success" icon="el-icon-refresh" @click="
              p.page = 1;
              f5();
            ">刷新</el-button>
        </el-form-item>
      </el-form>
      <el-table :row-class-name="tableRowClassName" :data="dataList" :key="conditionsKey" @selection-change="handleSelectionChange" :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }" size="mini">
        <el-table-column type="selection" width="70px"></el-table-column>
        <el-table-column label="自定义通道名称" prop="channelName" width="150px"> </el-table-column>
        <el-table-column label="自定义通道编号" prop="channelId" width="150px"></el-table-column>
        <el-table-column label="易支付通道编号" prop="channelEpayId" width="150px"></el-table-column>
        <el-table-column label="状态" width="120px">
          <template slot-scope="s">
            <el-switch :value="s.row.status" :active-value="1" :inactive-value="0" inactive-color="#ff4949" @change="setStatus(s.row)"></el-switch>
            <b style="color: green; margin-left: 10px" v-if="s.row.status == 1">启用</b>
            <b style="color: red; margin-left: 10px" v-if="s.row.status == 0">禁用</b>
          </template>
        </el-table-column>
        <el-table-column label="昨日收入" width="120px">
          <template slot-scope="s">
            <el-link style="color: green; margin-right: 5px; font-size: 14px">{{
              s.row.zrsr
            }}</el-link>
            <el-link icon="el-icon-refresh" style="font-size: 16px" @click="yesterDayRefreshFee(s.row)"></el-link>
          </template>
        </el-table-column>
        <el-table-column label="今日收入" width="120px">
          <template slot-scope="s">
            <el-link style="color: green; margin-right: 5px; font-size: 14px">{{
              s.row.jrsr
            }}</el-link>
            <el-link icon="el-icon-refresh" style="font-size: 16px" @click="toDayRefreshFee(s.row)"></el-link>
          </template>
        </el-table-column>
        <el-table-column label="限额" width="100px">
          <template slot-scope="s">
            <b>￥{{ s.row.limits }}</b>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="备注" prop="remarks" :formatter="remarksFormat">
        </el-table-column>
        <el-table-column prop="address" label="操作" width="150px" fixed="right">
          <template slot-scope="s">
            <el-button class="c-btn" type="success" icon="el-icon-edit" @click="update(s.row)">编辑</el-button>
            <el-dropdown style="margin-left: 5px;">
              <span class="el-dropdown-link">
                <el-button class="c-btn" type="primary" icon="el-icon-more">更多</el-button>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="el-icon-link" @click.native="testOrder(s.row)">测试订单</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="page-box">
        <el-pagination background layout="total, prev, pager, next, sizes" :current-page.sync="p.page" :page-size.sync="p.limit" :total="dataCount" :page-sizes="[1, 10, 20, 30, 40, 50, 100]" @current-change="f5(true)" @size-change="f5(true)">
        </el-pagination>
      </div>
    </div>
    <AddCustomCode ref="add-custom-code"></AddCustomCode>
  </div>
</template>

<script>
import AddCustomCode from "./add-custom-code.vue";
export default {
  components: { AddCustomCode },
  data() {
    return {
      p: {
        // 查询参数
        mchName: null,
        mchId: null,
        smid: null,
        type: 2,
        page: 1,
        limit: 10,
      },
      userDialogVisible: false,
      dataCount: 0,
      dataList: [],
      selectList: [],
      conditionsKey: 0,
      drawer: false,
      direction: 'rtl',
      multipleSelection: [],
    };
  },
  methods: {
    // 数据刷新
    f5: function () {
      if (this.sa_admin.role == 2) {
        var defaultCfg = {
          isBody: true, //是否是请求体请求
        };
        this.sa.ajax(
          "/v1/channel/list",
          this.p,
          function (res) {
            this.dataList = res.data.records; // 数据
            this.dataCount = res.data.total; // 分页
          }.bind(this),
          defaultCfg
        );
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection);
    },
    testOrder: function (data, var2) {
      let self = this;
      this.sa.prompt(
        "输入创建订单的金额",
        function (value) {
          var p1 = {
            id: data.id,
            amount: value,
            share: var2
          };
          var defaultCfg = {
            type: "get",
          };
          self.sa.ajax(
            "/v1/channel/payCustomTest",
            p1,
            function (res) {
              self.sa.showImageWithText(
                this.sa.cfg.api_url +
                "/v1/index/enQrcode?url=" +
                encodeURIComponent(res.data),
                "600px",
                "330px", res.data
              );
            }.bind(self),
            defaultCfg
          );
        },
        0
      );
    },
    // 查看
    yesterDayRefreshFee: function (data) {
      //获取金额
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      var p2 = {
        customWay: data.channelId, //是否是请求体请求
      };
      this.sa.ajax(
        "/v1/dataAnalysis/realtimeAmountYesterday",
        p2,
        function (res) {
          data.zrsr = "￥" + res.data;
          this.$set(this.dataList, data.index, data);
          this.conditionsKey++;
        }.bind(this),
        defaultCfg
      );
    },
    toDayRefreshFee: function (data) {
      //获取金额
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      var p2 = {
        customWay: data.channelId, //是否是请求体请求
      };
      this.sa.ajax(
        "/v1/dataAnalysis/realtimeAmountToDay",
        p2,
        function (res) {
          res.data = parseFloat(res.data).toFixed(2);
          data.jrsr = "￥" + res.data;
          this.$set(this.dataList, data.index, data);
          this.conditionsKey++;
        }.bind(this),
        defaultCfg
      );
    },
    setStatus: function (data) {
      this.sa.confirm(
        "是否要修改收款商户状态",
        function () {
          var defaultCfg = {
            type: "get", //是否是请求体请求
          };
          var params = data;
          this.sa.ajax(
            "/v1/channel/setStatus/" + data.id,
            function () {
              this.sa.ok("修改成功");
              params.status = params.status == 1 ? 0 : 1;
            }.bind(this), defaultCfg
          );
        }.bind(this)
      );
    },
    update: function (data) {
      this.$refs["add-custom-code"].open(data);
    },
    remarksFormat(row) {
      if (row.remarks == "" || row.remarks == null) {
        return "无备注";
      } else {
        return row.remarks;
      }
    },
    tableRowClassName({ row, rowIndex }) {
      //把每一行的索引放进row
      row.index = rowIndex;
    },
    loadmore() {
      // this.p.page++;
      // this.getMchCode();
    },
  },
  created: function () {
    this.f5();
  },
  directives: {
    "el-select-loadmore": {
      bind(el, binding) {
        const SELECTWRAP_DOM = el.querySelector(
          ".el-select-dropdown .el-select-dropdown__wrap"
        );
        SELECTWRAP_DOM.addEventListener("scroll", function () {
          const condition =
            this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) {
            binding.value();
          }
        });
      },
    },
  },
};
</script>

<style>
</style>
